
import React from 'react';
import { Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FormikProps } from 'formik';
import Label from "../../../components/Label/Label";
import { InstanceType } from "../../../redux/api/api.types";

type EncodingDefaultSelectProps = {
  formik: FormikProps<InstanceType>;
};

export default function EncodingDefaultSelect({ formik }: EncodingDefaultSelectProps) {
  // Find the current default encoding method
  const defaultEncodingIndex = formik.values.encodings.findIndex((enc) => enc.default === true);

  const handleDefaultSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedIndex = parseInt(event.target.value, 10);

    // Update the 'default' value for the selected encoding method
    const updatedEncodingMethods = formik.values.encodings.map((enc, index) => ({
      ...enc,
      default: index === selectedIndex,
    }));

    formik.setFieldValue("encodings", updatedEncodingMethods);
  };

  return (
    <Grid item xs={3}>
      <Label text="Default" />
      <Select
        fullWidth
        name="encodings.default"
        labelId="default-encoding-label"
        id="default-encoding-select"
        value={defaultEncodingIndex !== -1 ? defaultEncodingIndex.toString() : ""}
        onChange={handleDefaultSelectChange}
      >
        {formik.values.encodings.map((option, index) => {
          // Generate a label for each encoding, making SGTIN96 entries unique
          const label = option.method === "SGTIN96"
            ? `${option.method} (Partition: ${option.metadata.partition}, Prefix: ${option.metadata.companyPrefix || "N/A"})`
            : option.method;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={`${option.method}-${index}`} value={index.toString()}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
}

