import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FormikProps, FieldArray, FormikProvider } from 'formik';
import { EncodingType, InstanceType, Metadata } from "../../../redux/api/api.types";
import Label from "../../../components/Label/Label";
import BasicTooltip from '../../../components/BasicTooltip';
import HelperText from "../../../components/HelperText/HelperText";
import { HelperTextVariant, UIComponentSizeVarinats, EncodingMethodsTypes } from "../../../utils/enum/enum";
import EncodingTooltip from './EncodingTooltip';
import EncodingDefaultSelect from './EncodingDefaultSelect';

type EncodingMethodsProps = {
  formik: FormikProps<InstanceType>;
  isEdit?: boolean;
  ApiEncodingMethods?: EncodingType[];
};

const encodingList = [
  { key: "NID64", value: EncodingMethodsTypes.NID64 },
  { key: "NID32", value: EncodingMethodsTypes.NID32 },
  { key: "SGTIN96", value: EncodingMethodsTypes.SGTIN96 },
  { key: "LOOKUP", value: EncodingMethodsTypes.LOOKUP },
];

export default function EncodingMethods({ formik, isEdit, ApiEncodingMethods }: EncodingMethodsProps) {
  const [maxLimitMessage, setMaxLimitMessage] = useState("");
  const [isSGTIN96, setIsSGTIN96] = useState(false);

  const allEncodinglength = formik.values.encodings.length;

  const handleSelectChange = (event: { target: { value: string[] } }) => {
    const { value } = event.target;
    const uniqueSelectedValues = Array.from(new Set(value));
    // Build the new encoding list
    const newEncodingMethods = [];

    // Iterate through selected values
    uniqueSelectedValues.forEach((v) => {
      if (v === EncodingMethodsTypes.SGTIN96) {
        // Handle SGTIN96 specifically
        const apiMatches = isEdit
          ? ApiEncodingMethods?.filter(enc => enc.method === v) || []
          : [];

        // Preserve all existing SGTIN96 entries in the form
        const existingFormMatches = formik.values.encodings.filter(enc => enc.method === v);

        // Merge existing form entries and API entries (preserving unique entries)
        const mergedSGTIN = [...existingFormMatches];

        apiMatches.forEach((apiEnc) => {
          const isAlreadyIncluded = mergedSGTIN.some(formEnc =>
            formEnc.method === apiEnc.method &&
            JSON.stringify(formEnc.metadata) === JSON.stringify(apiEnc.metadata)
          );

          if (!isAlreadyIncluded) {
            mergedSGTIN.push({
              method: apiEnc.method,
              metadata: apiEnc.metadata,
              default: apiEnc.default,
              id: isEdit ? apiEnc.id : undefined,
            });
          }
        });

        if (mergedSGTIN.length === 0) {
          // Add a new default SGTIN96 entry if none exist
          mergedSGTIN.push({
            method: EncodingMethodsTypes.SGTIN96,
            metadata: { partition: 0, companyPrefix: "" },
            default: false,
          });
        }

        // Add the merged SGTIN96 entries to the new encoding list
        newEncodingMethods.push(...mergedSGTIN);
      } else {
        // For other methods, handle normally
        const existingFormValue = formik.values.encodings.find(enc => enc.method === v);
        const apiEncodingMethod = isEdit
          ? ApiEncodingMethods?.find(enc => enc.method === v)
          : null;

        newEncodingMethods.push({
          method: v,
          metadata: existingFormValue?.metadata || apiEncodingMethod?.metadata || {},
          default: existingFormValue?.default ?? apiEncodingMethod?.default ?? false,
          id: isEdit ? apiEncodingMethod?.id : undefined,
        });
      }
    });

    formik.setFieldValue("encodings", newEncodingMethods);

    const oldEncoding = value.join(",");
    formik.setFieldValue("encoding", oldEncoding);

    if (value.length >= 4) {
      setMaxLimitMessage("Maximum limit for encoding reached.");
      setIsSGTIN96(true);
    } else {
      setMaxLimitMessage("");
      setIsSGTIN96(false);
    }
  };

  const handleMenuItemClick = (optionValue: EncodingMethodsTypes) => {
    const currentEncoding = formik.values.encodings.map((enc) => enc.method);
    const newEncoding = currentEncoding.includes(optionValue)
      ? currentEncoding.filter((value) => value !== optionValue)
      : [...currentEncoding, optionValue];

    handleSelectChange({ target: { value: newEncoding } });
  };

  useEffect(() => {
    if (allEncodinglength !== undefined) {
      if (allEncodinglength >= 4) {
        setMaxLimitMessage("Maximum limit for encoding reached.");
        setIsSGTIN96(true);
      } else {
        setMaxLimitMessage("");
        setIsSGTIN96(false);
      }
    }
  }, [allEncodinglength, formik.values.encodings]);

  return (
    <Grid item xs={6} sx={{ pb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Label text="Encoding method" />
          <Box sx={{ display: "inline-block", verticalAlign: "middle", ml: 1 }}>
            <BasicTooltip text={<EncodingTooltip />} />
          </Box>
          <Select
            fullWidth
            labelId="encoding-label"
            multiple
            name="encodings"
            id="subscription_metadata_encoding"
            value={formik.values.encodings.map((enc) => enc.method)}
            renderValue={(selected) => selected.join(", ")}
            inputProps={{ "aria-label": "Without label" }}
            error={
              formik.touched.encodings &&
              Boolean(formik.errors.encodings)
            }
          >
            {encodingList.map((option) => (
              <MenuItem key={option.key} value={option.value}
                onClick={() => handleMenuItemClick(option.value)}
                disabled={isSGTIN96 && formik.values.encodings.map((enc) => enc.method).indexOf(option.value) === -1}
              >
                <Checkbox
                  checked={formik.values.encodings.map((enc) => enc.method).indexOf(option.value) > -1}
                  disabled={formik.values.encodings.map((enc) => enc.method).indexOf(option.value) === -1 && isSGTIN96}
                />
                <ListItemText primary={option.value} />
              </MenuItem>
            ))}
          </Select>
          {formik.errors.encodings && typeof formik.errors.encodings === 'string' && (
            <HelperText
              text={formik.errors.encodings as string}
            />
          )}

          {maxLimitMessage && (
            <HelperText
              text={maxLimitMessage}
              variant={HelperTextVariant.STANDARD}
            />
          )}

          {formik.values.encodings
            .map((item, originalIndex) => ({ ...item, originalIndex }))
            .filter((enc) => enc.method === "SGTIN96")
            .map((item) => (
              <FormikProvider key={item.originalIndex} value={formik}>
                <FieldArray
                  name={`encodings[${item.originalIndex}].metadata`}
                  render={() => (
                    <Grid container spacing={2} pt={2}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          name={`encodings[${item.originalIndex}].metadata.companyPrefix`}
                          value={item.metadata.companyPrefix}
                          size={UIComponentSizeVarinats.SMALL}
                          onChange={formik.handleChange}
                          placeholder="Company Prefix"
                          error={
                            formik.touched.encodings?.[item.originalIndex]?.metadata?.companyPrefix &&
                            Boolean(
                              (
                                (formik.errors.encodings?.[item.originalIndex] as unknown as Metadata)?.metadata?.companyPrefix as string
                              )
                            )
                          }
                        />
                        {(formik.errors.encodings?.[item.originalIndex] as {
                          metadata: {
                            companyPrefix: string
                          }
                        })?.metadata?.companyPrefix && (
                            <FormHelperText
                              variant={HelperTextVariant.STANDARD}
                              sx={{ mt: 0 }}
                            >
                              {
                                (
                                  (formik.errors.encodings?.[item.originalIndex] as {
                                    metadata: {
                                      companyPrefix: string
                                    }
                                  }
                                  )
                                    ?.metadata?.companyPrefix as string)
                              }
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          type='number'
                          name={`encodings[${item.originalIndex}].metadata.partition`}
                          value={item.metadata.partition}
                          onChange={formik.handleChange}
                          placeholder="Partition"
                          size={UIComponentSizeVarinats.SMALL}
                          sx={{ ml: 1 }}
                          error={
                            formik.touched.encodings?.[item.originalIndex]?.metadata?.partition &&
                            Boolean(
                              (
                                (formik.errors.encodings?.[item.originalIndex] as unknown as Metadata)?.metadata?.partition
                              )
                            )
                          }
                        />
                        {(formik.errors.encodings?.[item.originalIndex] as {
                          metadata: {
                            partition: string
                          }
                        })?.metadata?.partition && (
                            <FormHelperText
                              variant={HelperTextVariant.STANDARD}
                              sx={{ mt: 0 }}
                            >
                              {
                                (
                                  (formik.errors.encodings?.[item.originalIndex] as {
                                    metadata: {
                                      partition: string
                                    }
                                  }
                                  )
                                    ?.metadata?.partition as string)
                              }
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          type="button"
                          onClick={() => {
                            const updatedEncoding = formik.values.encodings.filter(
                              (_, i) => i !== item.originalIndex
                            );

                            formik.setFieldValue("encodings", updatedEncoding);

                            if (updatedEncoding.length < 4) {
                              setMaxLimitMessage("");
                              setIsSGTIN96(false);
                            }
                          }}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                />

              </FormikProvider>
            ))}
          {formik.values.encodings?.map((enc) => enc.method)?.includes(EncodingMethodsTypes.SGTIN96) && (
            <Button
              type="button"
              disabled={isSGTIN96}
              onClick={() => {
                const updatedEncoding = [
                  ...formik.values.encodings,
                  { method: "SGTIN96", metadata: { partition: 0, companyPrefix: "" }, default: false },
                ];

                if (allEncodinglength >= 4) {
                  setMaxLimitMessage("Maximum limit for encoding reached.");
                  setIsSGTIN96(true);
                } else {
                  setMaxLimitMessage("");
                  setIsSGTIN96(false);
                  formik.setFieldValue("encodings", updatedEncoding);
                }
              }}
            >
              Add MORE SGTIN96
            </Button>
          )}
        </Grid>
        <EncodingDefaultSelect formik={formik} />
      </Grid>
    </Grid>
  );
}
